import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/shahid/Desktop/Projects/Personal/madrasatulilm.com/src/views/layouts/MainLayout.js";
import { Helmet } from 'react-helmet';
import { Link } from 'gatsby';
import { Box, Breadcrumb, Divider, SEO, Text } from '../../views/components';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO pageTitle="আর্থিক ব্যাপার" mdxType="SEO" />
    <Box maxWidth={960} margin="0 auto" padding={{
      xs: 3,
      sm: 4
    }} mdxType="Box">
  <Text variant="h2" textAlign="center" mdxType="Text">
    আর্থিক ব্যাপার
  </Text>
  <Divider mdxType="Divider" />
  <Breadcrumb links={[{
        url: '/',
        name: 'নীড়পাতা'
      }, {
        url: '/finance/',
        name: 'আর্থিক ব্যাপার',
        isCurrentPage: true
      }]} mdxType="Breadcrumb" />
  <Divider mb={3} mdxType="Divider" />
  <div>
    ১. <Link to="/finance/expense-to-study-here/" mdxType="Link">এখানে পড়তে খরচ কত হবে</Link>
  </div>
  <div>
    ২.{' '}
    <Link to="/finance/your-donation-to-madrassa/" mdxType="Link">
      মাদ্রাসায় আপনার সাদাকাহ ও যাকাতে
    </Link>
  </div>
  <div>
    ৩.{' '}
    <Link to="/finance/business-of-madrassa/" mdxType="Link">
      মাদরাসাতুল ইলম ফুড এন্ড বুক শপ
    </Link>
  </div>
    </Box>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      